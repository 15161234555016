import { gql } from '@apollo/client/core'
import { imageMetaFragment } from 'services/images/image-meta.fragment'
import { imageFragment } from 'services/shared/image.fragment'
import { tagFragment } from 'services/tags/tags.fragment'
import { TECHNICAL_TAG_FRAGMENT } from 'services/technical-tags/technical-tags.query'
import {
    themeFragment,
    themeTranslationFragment,
    themeWithTranslatedVerticalsFragment,
} from 'services/themes/themes.fragment'
import { contributorFragment } from '../contributors/contributor.fragment'
import { continentFragment, countryFragment, locationFragment } from '../geo-locations/location.fragment'
import { languageFragment } from '../languages/language.fragment'

export const storyTextFragment = `
    fragment storyText on StoryText {
            storyId
            text
            format{
                id
                slug
            }
    }
`

export const storyMutateFragment = `
    fragment storyMutateInfos on Story {
        publicationStatus {
            id
            slug
        }
        updatedAt
        publishedAt
        urlTitleSlug
    }
`

export const storyReadMoreFragment = `
    ${storyMutateFragment}

    fragment storyReadMoreFragment on Story {
        id
        articleHeadline
        ...storyMutateInfos
        images {
            id
            url
        }
        event {
            id
            daletTitleName
            program {
                id
                slug
                canonicalUrl
            }
            publicationOwner {
                id
                slug
                canonicalUrl
            }
        }
        language {
            id
            isoCode
            websiteSubdomain
        }
    }
`

export const storyMainHeaderFragment = `
  fragment storyMainHeaderFragment on Story {
    storyId
    isVideoHidden
    isLivestreamActive
    iframe {
        displayRatioId
        startAt
        endAt
        url
    }
    image {
        id
        daletId
        daletUrl
        imageProviderId,
        usageInfos,
        metasStory(storyId: $storyId) {
            altText
            caption
            copyright {
                text
                link
            }
            source{
                text
                link
            }
            callToAction {
                text
                link
            }
        }
    }
    video {
        isSynchronized
        endAt
        url
        housenumber
        youtubeId
        dailymotionId
    }
    file
    fieldsToReplicate
  }
`

export const storyFragment = `
    fragment storyInfo on Story {
        id
        masterCms
        daletPlanningArea
        type {
            id
            slug
        }
        event {
            id
            daletTitleName
            daletVersionId
            isMagazine
            productionNote
            eventSource {
                id
                slug
              }
            producerLanguage {
                id
            }
            programDeliverable {
                id
                slug
                title
            }
            embargoDate
            program {
                id
                slug
                isDisabledCms
                translations {
                    title
                    language {
                        id
                        isoCode
                    }
                    websiteMenu
                }
                theme {
                    ...themeWithTranslatedVerticalsInfo
                    translations {
                        ...themeTranslationInfo
                    }
                }
                publicationOwner {
                    id
                    labelLong
                    labelShort
                }
                canonicalUrl
            }
            publicationOwner {
                id
                slug
                canonicalUrl
            }
            stories {
                id
                updatedAt
                images {
                    id
                }
                publicationStatus {
                    id
                    slug
                }
                language {
                    id
                    isoCode
                    fullname
                }
                isStoryTranslationSource
            }
        }
        campaigns {
            id
            slug
            title
            startAt
            endAt
            isActive
        }
        language {
            id
            isoCode
            isRTL
            isUsingEnSlug
            websiteSubdomain
        }
        publicationStatus {
            id
            slug
        }
        isIndexed
        reviewStatus
        housenumber
        isStoryTranslationSource
        storyTranslationMethod {
          id
          slug
          name
          description
          isActive
          isAutomatic
        }
        alert {
            id
            title
            publishedAt
            updatedAt
            isActive
            type {
                id
                slug
            }
            targetType{
                id
                slug
            }
            publicationChannels {
                id
                type {
                    id
                    slug
                }
                owner {
                    id
                    slug
                    labelLong
                    labelShort
                    canonicalUrl
                }
                labelLong
                labelShort
            }
        }
        alertsHistory {
            id
            title
            publishedAt
            isActive
        }
        pushMessages {
            id
            title
            message
            createdAt
            pushedAt
        }
        articleHeadline
        articleHeadlineAlt
        callToActionHeadline
        SEOMetaTitle
        shortTitle
        socialTitle
        summary
        signature {
            name
            twitter
        }
        hashtag
        urlTitleSlug
        publishedAt
        firstPublishedAt
        lastPublishedAt
        expiresAt
        createdAt
        createdBy
        updatedAt
        isVideoHidden
        author {
            ...contributorInfo
        }
        videoEditor {
            ...contributorInfo
        }
        producer {
            ...contributorInfo
        }
        assignee {
            ...contributorInfo
        }
        contributors {
            ...contributorInfo
        }
        isLiveCoverage
        isLivestreamActive
        isBreakingNews
        masterCms
        texts {
            ...storyText
        }
        publicationChannels {
            id
            type {
                id
                slug
            }
            owner {
                id
                slug
                labelLong
                labelShort
                canonicalUrl
            }
            labelLong
            labelShort
        }
        images {
            ...imageInfo
            provider {
                id
                name
            }
            usageInfos
            metasStory(storyId: $storyId) {
                ...imageMetaInfo
            }
            storyImageTypeId
        }
        videos {
            url
            quality {
                id
                slug
            }
            type {
                id
                slug
            }
            format {
                id
                slug
            }
            isSynchronized
            housenumber
            youtubeId
            dailymotionId
            endAt
        }
        youtubeId
        dailymotionId
        iframe {
            url
            startAt
            endAt
            displayRatioId
        }
        themes {
            ...themeInfo
        }
        additionalReporting {
            storyId
            agencies
            contributors
            sourceMessage
            contributionMessages
        }
        tags {
            ...tagInfo
        }
        technicalTags {
            ...technicalTagInfo
            parent {
                ...technicalTagInfo
                parent {
                    ...technicalTagInfo
                    parent {
                        ...technicalTagInfo
                    }
                }
            }
        }
        geo {
            continent {
                ...continentInfo
            }
            country {
                ...countryInfo
            }
            location {
                ...locationInfo
            }
        }
        daletLink {
            daletEventId
            langId
        }
        daletItemcodes {
            daletItemcode
            daletDigitalId
            createdAt
            notifiedAt
        }
        version
        totalChars
    }
    ${languageFragment}
    ${storyTextFragment}
    ${contributorFragment}
    ${continentFragment}
    ${countryFragment}
    ${locationFragment}
    ${imageFragment}
    ${imageMetaFragment}
    ${tagFragment}
    ${themeFragment}
    ${themeTranslationFragment}
    ${themeWithTranslatedVerticalsFragment}
    ${TECHNICAL_TAG_FRAGMENT}
`

export const storyWithRelatedFragment = `
fragment storyWithRelatedInfo on Story {
    ...storyInfo,
    related {
      ...storyInfo
    }
}
${storyFragment}
`

export const findByIdQuery = gql`
    ${languageFragment}

    ${storyWithRelatedFragment}

    query getStory($storyId: Int!) {
        story(id: $storyId) {
            ...storyWithRelatedInfo
        }
    }
`

export const findByIdLightQuery = gql`
    ${storyReadMoreFragment}

    query getStoriesList($storyId: [Int]!) {
        storiesList(ids: $storyId) {
            ...storyReadMoreFragment
        }
    }
`

export const storyWithRelatedFragmentGpl = gql`
    ${storyWithRelatedFragment}
`
export const storyMainHeaderFragmentGpl = gql`
    ${storyMainHeaderFragment}
`
