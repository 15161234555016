import { Directive, Input, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { ClrDatagridStateInterface } from '@clr/angular'
import * as dayjs from 'dayjs'
import { User } from 'entities/user'
import {
    BaseDashboardSearchFilters,
    FilterName,
    FilterTypes,
    MappedFilterName,
    ToolbarFilterLabel,
    ToolbarFilterName,
} from 'enums/filters'
import { LanguageIsoCode } from 'enums/language'
import { LanguageModel } from 'models/language.model'
import { from, Observable, Subject } from 'rxjs'
import { takeUntil, map } from 'rxjs/operators'
import { AuthService } from 'services/auth/auth'
import { DashboardConstantsService } from 'services/dashboard/dashboard-constants.service'
import { DashboardFiltersListService } from 'services/dashboard/filters-list/dashboard-filters-list.service'
import { DashboardFilterHelper } from 'services/helpers/dashboard-filters.helper'
import { LanguagesService } from 'services/languages/languages'
import { VerticalService } from 'services/verticals/verticals.service'
import { encodeJSON } from 'tools/json-encoder'
import { DatagridAbstractFilterComponent } from '../filter/datagrid-abstract-filter.component'
import { DatagridFilterableComponent } from '../filter/datagrid-filterables/datagrid-filterable.component'
import {
    ContributorDatagridFiltersInterface,
    DatagridFilterInterface,
    FilterableInterface,
} from '../filter/datagrid-filterables/filterables.interface'
import { DashboardPaginationAbstract } from './dashboard-pagination-abstract.component'
import { DASHBOARD_TYPE } from './dashboard.enums'

@Directive()
export abstract class DashboardAbstract<T extends BaseDashboardSearchFilters> extends DashboardPaginationAbstract {
    abstract dashboardType: DASHBOARD_TYPE

    @Input() contributor: User

    @ViewChildren(DatagridFilterableComponent)
    datagridDashboardFilters: QueryList<DatagridAbstractFilterComponent<any>>
    @ViewChildren('publicationDate, updatedAtDate, createdAtDate')
    datagridDashboardDateFilters: QueryList<DatagridAbstractFilterComponent<any>>
    @ViewChild('languagesListFilter') languagesListFilter: DatagridFilterableComponent

    // Toolbar filters
    protected toolbarFiltersList: FilterableInterface[] = []

    // Datagrid filters
    protected channelsList: FilterableInterface[] = []
    protected desksList: FilterableInterface[] = []
    protected currentContributorList: FilterableInterface[] = []
    protected languagesList: FilterableInterface[] = []
    protected programsList: FilterableInterface[] = []
    protected verticalsList: FilterableInterface[] = []
    protected statusesList: FilterableInterface[] = []

    // Constants
    protected FilterName = FilterName
    protected FilterTypes = FilterTypes
    protected LanguageIsoCode = LanguageIsoCode
    protected contributorDatagridFilters: ContributorDatagridFiltersInterface = {}

    // OTHER
    protected dashboardSearchFilters: T
    protected isLoading: boolean = true
    protected contributorLanguage: number
    protected showRowDetails: boolean = false

    protected activeDatagridFilters: ContributorDatagridFiltersInterface = {}
    protected nextSearch = new Subject<void>()

    filters = {}

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected dashboardFiltersListService: DashboardFiltersListService,
        protected dashboardConstantsService: DashboardConstantsService,
        protected authService: AuthService,
        protected languagesService: LanguagesService,
        protected verticalsService: VerticalService,
    ) {
        super()
    }

    get page(): number {
        return this.pageOffset / this.itemsPerPage + 1
    }

    get checkedAllLanguages(): boolean {
        return this.languagesListFilter?.value.length === this.languagesList.length
    }

    protected abstract loadStatusesList(): void

    protected abstract getDashboardSearchFiltersFromDatagrid(state: ClrDatagridStateInterface): T

    /**
     * Reload the component with the query params search filters
     * and the pagination offset to launch a new search
     */
    protected reloadWithQueryParamFilters(): Observable<boolean> {
        const encodedFilters = encodeJSON(this.activeDatagridFilters, { removeFalsyValues: true })

        const currentDashboardQueryParams: Params = {
            [`${this.dashboardType}Page`]: this.pageOffset ? this.page : undefined,
            [`${this.dashboardType}Filters`]: Object.keys(this.activeDatagridFilters).length
                ? encodedFilters
                : undefined,
        }

        return from(
            this.router.navigate([], {
                queryParams: { ...this.route.snapshot.queryParams, ...currentDashboardQueryParams },
                relativeTo: this.route,
                replaceUrl: true,
            }),
        )
    }

    protected loadChannelsList(): void {
        this.channelsList = this.dashboardFiltersListService.loadChannelFilterList()
    }

    protected loadDesksList(): void {
        this.desksList = this.dashboardFiltersListService.loadDeskFilterList()
    }

    protected loadCurrentContributorList(): void {
        this.currentContributorList = this.dashboardFiltersListService.loadCurrentContributorList(this.contributor)
    }

    protected loadLanguagesList(): void {
        this.languagesList = this.dashboardFiltersListService.loadlanguageFilterList()
    }

    protected loadProgramsList(): Observable<void> {
        this.dashboardFiltersListService.runProgramListLoader()

        return this.dashboardFiltersListService.loadProgramsList(this.contributor).pipe(
            takeUntil(this.ngUnsubscribe),
            map(programList => {
                this.programsList = programList
            }),
        )
    }

    protected loadVerticalsList(): Observable<void> {
        this.verticalsService.getList()

        return this.verticalsService.verticals.pipe(
            takeUntil(this.ngUnsubscribe),
            map(verticals => {
                this.verticalsList = verticals.map(vertical => ({
                    id: vertical.id,
                    label: vertical.translations[0].title!,
                    group: '',
                }))
            }),
        )
    }

    /**
     * Init the refresh of the stories or events when the refresh is triggered by storiesService or eventsService
     */
    protected initRefreshSubscription(refreshService: any): void {
        refreshService.refresh.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.nextSearch.next())
    }

    /**
     * Save a contributor date filter in the activeDatagridFilters property
     * with the right date format
     */
    protected setContributorDateFilter(filterName: string, filters: Record<string, any>): void {
        if (!filters || !filters[filterName]) {
            this.onDatagridFilterSelected(filterName, null)

            return
        }

        const currentFilter = filters[filterName]

        let [from, to] = currentFilter
        from = dayjs(from).format(this.dashboardConstantsService.DATE_FORMAT)
        to = dayjs(to).format(this.dashboardConstantsService.DATE_FORMAT)
        const label = `${filterName} : ${from} to ${to}`

        this.onDatagridFilterSelected(filterName, {
            filterTitle: filterName,
            label,
            data: currentFilter,
        })
    }

    /**
     * Trigger a new search when the datagrid filters or pagination are updated
     */
    public onDatagridRefresh(state: ClrDatagridStateInterface): void {
        this.dashboardSearchFilters = this.getDashboardSearchFiltersFromDatagrid(state)
        this.pageOffset = this.getPaginationOption(state)[1]

        this.nextSearch.next()
    }

    protected resetToolbarFilterByName(toolbarFilter: MappedFilterName): void {
        // Removing the filter from activeContributorFilter (it will disappears from contributor filter list)
        this.onDatagridFilterSelected(toolbarFilter.filterName, null)
        // Set the selected filter value to false (uncheck the filter checkbox in the toolbar)
        this.toolbarFiltersList = this.toolbarFiltersList.map(filter => {
            if (toolbarFilter?.id === filter.id) {
                filter.selected = false
            }

            return filter
        })
        // Disable the filter from the stories search object so the request won't use it
        this.dashboardSearchFilters.options = this.dashboardSearchFilters.options.map(filter => {
            if (toolbarFilter?.id === filter.id) {
                filter.value = false
            }

            return filter
        })
    }

    protected resetAllToolbarFilters(): void {
        this.dashboardConstantsService.TOOLBAR_FILTERS_NAMES_MAPPING_EVENTS.forEach(filter => {
            this.resetToolbarFilterByName(filter)
        })
    }

    protected clearAllFilters(): void {
        this.resetAllToolbarFilters()
        this.datagridDashboardDateFilters.toArray().forEach(dateRangeFilter => {
            dateRangeFilter.resetValue()
        })
        this.datagridDashboardFilters.toArray().forEach(datagridFilter => {
            datagridFilter.resetValue()
        })
    }

    protected applyToolbarFilters(contributorFilters: ContributorDatagridFiltersInterface): void {
        this.toolbarFiltersList = this.toolbarFiltersList.map(toolbarFilter => {
            const currentFilterName = DashboardFilterHelper.getFilterNameFromMapping(
                toolbarFilter.id,
                this.dashboardConstantsService.TOOLBAR_FILTERS_NAMES_MAPPING_EVENTS,
            )
            const isCurrentFilterIsActive = DashboardFilterHelper.filterIsActiveContributorFilter(
                currentFilterName,
                contributorFilters,
            )

            toolbarFilter.selected = isCurrentFilterIsActive

            this.dashboardSearchFilters = DashboardFilterHelper.setToolbarFilterValue(
                this.dashboardSearchFilters,
                toolbarFilter,
            )
            this.onDatagridFilterSelected(currentFilterName, DashboardFilterHelper.buildFilterData(toolbarFilter))

            return toolbarFilter
        })
    }

    protected loadToolbarFiltersList(contributorFilters: ContributorDatagridFiltersInterface): void {
        if (DashboardFilterHelper.isEmpty(contributorFilters)) {
            this.clearAllFilters()

            return
        }
        this.applyToolbarFilters(contributorFilters)
    }

    protected resetInactiveDashboardFilters(contributorFilters: ContributorDatagridFiltersInterface): void {
        const filtersToReset = DashboardFilterHelper.getDisabledDashboardFilters({
            contributorFilters,
            datagridDashboardDateFilters: this.datagridDashboardDateFilters,
            datagridDashboardFilters: this.datagridDashboardFilters,
        })
        if (!filtersToReset?.length) {
            return
        }
        filtersToReset.forEach(filter => {
            filter.resetValue()
        })
    }

    /**
     * Apply all the contributor filters to the toolbar and the datagrid
     * and trigger a new search
     */
    protected onContributorFiltersSelected(contributorFilters: ContributorDatagridFiltersInterface): void {
        this.loadToolbarFiltersList(contributorFilters)
        this.resetInactiveDashboardFilters(contributorFilters)
        this.contributorDatagridFilters = contributorFilters

        this.nextSearch.next()
    }

    protected getContributorDefaultLanguage(): LanguageModel {
        return (
            this.languagesService.languageByIsoCode[this.contributor.getDefaultLanguageIsoCode()] ||
            this.languagesService.defaultLanguage
        )
    }

    protected resetAllLanguagesFilters(): void {
        this.languagesList = this.languagesList.map(language => {
            language.selected = language.id === this.getContributorDefaultLanguage().id

            return language
        })
    }

    /**
     * Save the datagrid filters in the activeDatagridFilters property
     * that is used to save the contributor filters and to generate the query params
     */
    protected onDatagridFilterSelected(filterName: string, filter: DatagridFilterInterface | null): void {
        if (!filter) {
            delete this.activeDatagridFilters[filterName]
            this.activeDatagridFilters = { ...this.activeDatagridFilters }
            if (filterName === FilterName.LANGUAGES && this.dashboardType === DASHBOARD_TYPE.STORIES) {
                this.resetAllLanguagesFilters()
            }

            return
        }

        this.activeDatagridFilters = {
            ...this.activeDatagridFilters,
            [filterName]: filter,
        }
    }

    /**
     * Trigger a new search when a toolbar filter is updated
     */
    protected onToolbarFilterSelected(filter: FilterableInterface): void {
        filter.selected = !filter.selected
        this.dashboardSearchFilters = DashboardFilterHelper.setToolbarFilterValue(this.dashboardSearchFilters, filter)

        Object.entries(ToolbarFilterLabel).forEach(([key, label]) => {
            if (label === filter.label) {
                this.onDatagridFilterSelected(ToolbarFilterName[key], DashboardFilterHelper.buildFilterData(filter))
            }
        })

        this.pageOffset = 0

        this.nextSearch.next()
    }

    /**
     * Set the default values for the datagrid and toolbar filters
     */
    protected setDefaultFilterValues(): void {
        this.dashboardSearchFilters =
            this.dashboardType === DASHBOARD_TYPE.EVENTS
                ? (this.dashboardConstantsService.DEFAULT_EVENTS_REQUEST_DTO as T)
                : (this.dashboardConstantsService.DEFAULT_STORIES_REQUEST_DTO as T)
        this.toolbarFiltersList = this.dashboardConstantsService.TOOLBAR_FILTERS_LIST_EVENTS
        this.contributorLanguage = this.authService.getUserDefaultLanguageId()
    }

    public getStatusClass(abbreviation: string | undefined): string {
        if (!abbreviation) {
            return ''
        }

        return 'eventStatus eventStatus--' + abbreviation.toLowerCase()
    }
}
