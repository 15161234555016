import { gql } from '@apollo/client/core'
import { imageMetaFragment } from 'services/images/image-meta.fragment'
import { imageFragment } from 'services/shared/image.fragment'
import { storyMutateFragment } from '../find-by-id.query'

export const storyMainHeaderFragment = `
    ${imageFragment}
    ${imageMetaFragment}

    fragment storyMainHeaderInfos on Story {
        housenumber
        isLiveCoverage
        isBreakingNews
        isVideoHidden
        isLivestreamActive
        images {
            ...imageInfo
            provider {
                id
                name
            },
            usageInfos,
            metasStory(storyId: $storyId) {
                ...imageMetaInfo
            }
            storyImageTypeId
        }
        videos {
            url
            quality {
                id
                slug
            }
            type {
                id
                slug
            }
            format {
                id
                slug
            }
            housenumber
            isSynchronized
            endAt
        }
        youtubeId
        dailymotionId
        iframe {
            url
            startAt
            endAt
            displayRatioId
        }
    }
`

export const updateStoryMainHeader = gql`
    ${storyMainHeaderFragment}
    ${storyMutateFragment}

    mutation updateStoryMainHeader(
        $storyId: Int!
        $isVideoHidden: Boolean
        $isLivestreamActive: Boolean
        $iframe: StoryIframeInput
        $video: StoryVideoInput
        $image: StoryImageInput
        $fieldsToReplicate: [StoryMainHeaderReplicatedFields]
    ) {
        updateStoryMainHeader(
            storyId: $storyId
            isVideoHidden: $isVideoHidden
            isLivestreamActive: $isLivestreamActive
            iframe: $iframe
            video: $video
            image: $image
            fieldsToReplicate: $fieldsToReplicate
        ) {
            ...storyMutateInfos
            ...storyMainHeaderInfos
        }
    }
`

export const getStoryMainHeader = gql`
    ${storyMainHeaderFragment}
    ${storyMutateFragment}

    query getStoryMainHeader($storyId: Int!) {
        story(id: $storyId) {
            id
            daletLink {
                daletEventId
                langId
            }
            event {
                id
                isMagazine
                stories {
                    id
                    images {
                        id
                    }
                }
            }
            ...storyMutateInfos
            ...storyMainHeaderInfos
        }
    }
`

export const updateStoryWidgetImage = gql`
    mutation uploadStoryWidgetImage(
        $storyId: Int!
        $imageProviderId: Int
        $usageInfos: String
        $metas: [StoryImageMetaInput]
        $fileName: String
    ) {
        uploadStoryWidgetImage(
            storyId: $storyId
            imageProviderId: $imageProviderId
            usageInfos: $usageInfos
            metas: $metas
            fileName: $fileName
        ) {
            id
            url
        }
    }
`

export const uploadStoryWireImage = gql`
    mutation uploadStoryWireImage($storyId: Int!, $fileUrl: String!, $mainHeader: Boolean) {
        uploadStoryWireImage(storyId: $storyId, fileUrl: $fileUrl, mainHeader: $mainHeader)
    }
`
export const getImageByIdQuery = gql`
    ${imageFragment}

    query image($id: Int!) {
        image(id: $id) {
            ...imageInfo
            imageProviderId
            usageInfos
        }
    }
`

export const getImageByUrlQuery = gql`
    ${imageFragment}

    query imageByUrl($url: String!) {
        imageByUrl(url: $url) {
            ...imageInfo
            imageProviderId
            usageInfos
        }
    }
`
export const updateImageProviderAndUsageInfo = gql`
    mutation updateImageProviderAndUsageInfo($imageId: Int!, $imageProviderId: Int!, $usageInfos: String!) {
        updateImageProviderAndUsageInfo(imageId: $imageId, imageProviderId: $imageProviderId, usageInfos: $usageInfos)
    }
`
