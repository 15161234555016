import { gql } from '@apollo/client/core'
import { paginationFragment } from '../shared/pagination.fragment'
import { tagFragment } from './tags.fragment'

export const tagsQuery = gql`
    ${paginationFragment}
    ${tagFragment}

    query tags($moderationStatus: TagModerationStatus, $pagination: PaginationInput, $order: [OrderInput]) {
        tags(moderationStatus: $moderationStatus, options: { pagination: $pagination, order: $order }) {
            results {
                ...tagInfo
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`

export const tagsSearch = gql`
    ${paginationFragment}
    ${tagFragment}

    query tagsSearch($search: SearchTagInput, $moderationStatus: TagModerationStatus, $pagination: PaginationInput) {
        tags(
            options: { pagination: $pagination, order: { key: "translations.title", direction: ASC } }
            search: $search
            moderationStatus: $moderationStatus
        ) {
            results {
                ...tagInfo
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`

export const tagByIdQuery = gql`
    ${tagFragment}

    query tag($id: Int!) {
        tag(id: $id) {
            ...tagInfo
        }
    }
`

export const upsertTag = gql`
    ${tagFragment}

    mutation upsertTag($tag: TagInput!) {
        upsertTag(tag: $tag) {
            ...tagInfo
        }
    }
`

export const moderateTag = gql`
    ${tagFragment}

    mutation moderateTag($tagId: Int!, $approved: Boolean!) {
        moderateTag(tagId: $tagId, approved: $approved) {
            ...tagInfo
        }
    }
`

export const disableTag = gql`
    ${tagFragment}

    mutation disableTag($tagId: Int!, $disabled: Boolean!) {
        disableTag(tagId: $tagId, disabled: $disabled) {
            ...tagInfo
        }
    }
`

export const toggleOpinionDisclaimer = gql`
    ${tagFragment}

    mutation toggleOpinionDisclaimer($tagId: Int!, $showOpinionDisclaimer: Boolean!) {
        toggleOpinionDisclaimer(tagId: $tagId, showOpinionDisclaimer: $showOpinionDisclaimer) {
            ...tagInfo
        }
    }
`

export const specifyTag = gql`
    ${tagFragment}

    mutation specifyTag($tagId: Int!, $isSpecific: Boolean!, $message: String) {
        specifyTag(tagId: $tagId, isSpecific: $isSpecific, message: $message) {
            ...tagInfo
        }
    }
`

export const deleteTag = gql`
    mutation deleteTag($tagId: Int!) {
        deleteTag(id: $tagId)
    }
`

export const tagsBySlugAndLanguage = gql`
    ${paginationFragment}
    ${tagFragment}

    query tagsBySlugAndLanguage($slug: String!, $language: Int, $moderationStatus: TagModerationStatus) {
        tagsBySlugAndLanguage(slug: $slug, language: $language, moderationStatus: $moderationStatus) {
            results {
                ...tagInfo
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`

export const tagsBySlugs = gql`
    ${tagFragment}

    query tagsBySlugs($slugs: [String!]!) {
        tagsBySlugs(slugs: $slugs) {
            ...tagInfo
        }
    }
`

export const updateTagTranslationDescription = gql`
    mutation updateTagTranslationDescription($tagId: Int!, $langId: Int!, $description: String!) {
        updateTagTranslationDescription(tagId: $tagId, langId: $langId, description: $description)
    }
`
