import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { AISettings } from 'interfaces/ai'
import { Observable } from 'rxjs'
import { pluck } from 'rxjs/operators'
import { networkQuery } from 'tools/apollo'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import {
    generateEditorialTagsDescriptionsMutation,
    generateMainHeadlineQuery,
    generateRunningTitleQuery,
    generateStoryTwitterTextQuery,
    generateSummaryQuery,
    settingsQuery,
    translateEditorialTagsDescriptionsMutation,
    updateSettingsMutation,
} from './ai.query'

@Injectable()
export class AIService extends BaseService {
    constructor(apollo: Apollo, protected errorMessagesServices: ErrorsMessagesService) {
        super(apollo, errorMessagesServices)
    }

    getSettings() {
        return networkQuery<AISettings[]>(
            this.apollo,
            {
                query: settingsQuery,
            },
            ['data', 'aiSettings'],
        )
    }

    updateSettings(aiSettings: AISettings) {
        return this.apollo
            .mutate({
                mutation: updateSettingsMutation,
                variables: {
                    aiSettings,
                },
            })
            .pipe(pluck('data', 'updateAISettings'))
    }

    generateMainHeadline(storyId: number) {
        return networkQuery<string[]>(
            this.apollo,
            {
                query: generateMainHeadlineQuery,
                variables: {
                    storyId,
                },
            },
            ['data', 'aiGenerateMainHeadline'],
        )
    }

    generateRunningTitle(mainHeadline: string) {
        return networkQuery<string[]>(
            this.apollo,
            {
                query: generateRunningTitleQuery,
                variables: {
                    mainHeadline,
                },
            },
            ['data', 'aiGenerateRunningTitle'],
        )
    }

    generateStoryTwitterText(storyId: number) {
        return networkQuery<string[]>(
            this.apollo,
            {
                query: generateStoryTwitterTextQuery,
                variables: {
                    storyId,
                },
            },
            ['data', 'aiGenerateStoryTwitterText'],
        )
    }

    generateSummary(storyId: number) {
        return networkQuery<string[]>(
            this.apollo,
            {
                query: generateSummaryQuery,
                variables: {
                    storyId,
                },
            },
            ['data', 'aiGenerateSummary'],
        )
    }

    generateEditorialTagsDescriptions(slugs: string[], overwrite: boolean): Observable<any> {
        return this.apollo.mutate({
            mutation: generateEditorialTagsDescriptionsMutation,
            variables: {
                slugs,
                overwrite,
            },
        })
    }

    translateEditorialTagsDescriptions(tagIds: number[], overwrite: boolean): Observable<any> {
        return this.apollo.mutate({
            mutation: translateEditorialTagsDescriptionsMutation,
            variables: {
                tagIds,
                overwrite,
            },
        })
    }
}
