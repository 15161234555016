import * as clone from 'clone'
import * as dayjs from 'dayjs'
import { DaletVersionId } from 'enums/dalet'
import { ProgramDeliverableSlug } from 'enums/program-deliverable'
import { PublicationOwnerName } from 'enums/publication-owner-name'
import { PublicationStatus } from 'enums/publication-status'
import { StoryProgramNameSlug, StoryTypeSlug } from 'enums/story'
import { StoryTextFormatsSlug, StoryTextFormatsType } from 'enums/story-text-format'
import { UserRole } from 'enums/user-role'
import { WireImportDefaults } from 'enums/wire-import'
import { environment } from 'environments/environment'
import { StoryHasDaletItemcodeModel } from 'models/story-has-dalet-itemcode.model'
import { TechnicalTagModel } from 'models/technical-tag.model'
import { AuthService } from 'services/auth/auth'
import { ContributorJobs } from '../enums/contributor-jobs'
import { IconMenuStatus } from '../enums/icon-menu-status'
import { ReviewStatus } from '../enums/review-status'
import { StoryImageType } from '../enums/story-image-type'
import { Story as IStory } from '../interfaces/story'
import { StoryCheck } from '../interfaces/story-check'
import { AdditionalReporting, AdditionalReportingSimplify } from '../models/additional-reporting.model'
import { AlertModel } from '../models/alert.model'
import { ContributorModel } from '../models/contributor.model'
import { DaletLinkModel } from '../models/dalet-link.model'
import { LanguageModel } from '../models/language.model'
import { LocationsModel } from '../models/location.model'
import { Iframe, Image, MetasImage, Video } from '../models/media.model'
import { NativeAdsModel } from '../models/native-ads.model'
import { PublicationChannelModel, PublicationOwnerModel, PublicationStatusModel } from '../models/publication.model'
import { PushMessageModel } from '../models/push-message.model'
import { SourceModel } from '../models/source.model'
import { StorySignatureModel } from '../models/story-signature.model'
import { RelatedStory, StoryEvent, StoryLog, StoryModel, StoryText, StoryType } from '../models/story.model'
import { TagModel } from '../models/tag.model'
import { ThemeModel } from '../models/theme.model'
import { TranslationTagModel, StoryTranslationMethodModel } from '../models/translation.model'

export class Story implements IStory, StoryCheck {
    housenumber?: string
    daletPlanningArea?: string
    id: number
    event: StoryEvent
    articleHeadline: string
    firstPublisher?: ContributorModel
    author?: ContributorModel
    videoEditor?: ContributorModel
    producer?: ContributorModel
    assignee?: ContributorModel
    reviewStatus?: ReviewStatus
    publicationStatus?: PublicationStatusModel
    isIndexed: boolean
    isStoryTranslationSource?: boolean
    storyTranslationMethod?: StoryTranslationMethodModel
    titleSeo: string
    shortTitle: string
    socialTitle: string
    createdAt: Date
    updatedAt: Date
    publishedAt: Date
    firstPublishedAt: Date
    lastPublishedAt: Date
    expiresAt: Date
    updatedBy: ContributorModel
    createdBy: ContributorModel
    language: LanguageModel
    additionalReporting: AdditionalReporting
    publicationChannels: PublicationChannelModel[]
    summary: string
    hashtag: string | any
    urlTitleSlug: string
    texts: StoryText[]
    contributors: ContributorModel[]
    tags: TagModel[] = []
    technicalTags: TechnicalTagModel[] = []
    related: RelatedStory[] = []
    themes: ThemeModel[] = []
    alert: AlertModel
    alertsHistory: [AlertModel]
    images: Image[] = []
    iframe: Iframe
    videos: Video[]
    youtubeId: string
    dailymotionId: string
    isVideoHidden: boolean
    sources: SourceModel[]
    type: StoryType
    geo: LocationsModel = {}
    isLiveCoverage: boolean
    isLivestreamActive: boolean
    isBreakingNews: boolean
    masterCms: string
    pushMessages: PushMessageModel[]
    daletLink: DaletLinkModel
    daletItemcodes: StoryHasDaletItemcodeModel[]
    campaigns: NativeAdsModel[]
    version: number
    signature: StorySignatureModel
    totalChars?: number
    logs?: StoryLog[]
    isDaletPyramid: boolean
    isDaletGalaxy: boolean

    private minTagsRequired: number = 3
    private maxTagsAllowed: number = 6

    protected contributorJobs = ContributorJobs

    /**
     *
     * @param story
     */
    constructor(story: StoryModel) {
        const copy = clone(story)
        Object.assign(this, copy)

        this.isDaletPyramid = this.event?.daletVersionId === DaletVersionId.PYRAMID
        this.isDaletGalaxy = this.event?.daletVersionId === DaletVersionId.GALAXY
    }

    /**
     *
     * @returns {Story}
     */
    cloneForModifiedProps(): IStory {
        return new Story({
            id: this.id,
            event: this.event,
            articleHeadline: this.articleHeadline,
            language: this.language,
        })
    }

    /**
     *
     * @param additionalReporting
     * @returns {Story}
     */
    public addAdditionalReporting(additionalReporting: AdditionalReportingSimplify | AdditionalReporting): Story {
        if (!this.additionalReporting || this.additionalReporting === undefined) {
            this.additionalReporting = {
                storyId: this.id,
            }
        }

        Object.keys(additionalReporting).forEach(key => {
            if (additionalReporting[key] === undefined) {
                return
            }

            this.additionalReporting[key] = additionalReporting[key]
        })

        return this
    }

    /**
     * GQL Story only return alert if it exists and it's active that's why it never send us the is_active field
     *
     * @returns {boolean}
     */
    public isAlert(): boolean {
        return typeof this.alert !== 'undefined' && this.alert !== null
    }

    /**
     * Checks if a story is a type 'Top News Stories Today'
     * @returns {boolean}
     */
    public isTopNewsStoriesToday(): boolean | undefined {
        return this.event.program && this.event.program.slug === StoryProgramNameSlug.TOP_NEWS_STORIES_TODAY
    }

    /**
     * Checks if the story program is 'No Comment'
     * @returns {boolean}
     */
    public isProgramNoComment(): boolean {
        return this.event.program?.slug === 'nocomment'
    }

    /**
     * Checks if the story program is 'World News'
     * @returns {boolean}
     */
    public isProgramWorld(): boolean {
        return this.event.program?.slug === 'world'
    }

    /**
     * Checks if the story program is enable.
     * @returns {boolean}
     */
    public isProgramTranslationDisplayWebsite(langId: number): boolean {
        return !!this.event.program?.translations.find(translation => translation.language.id === langId)?.websiteMenu
    }

    /**
     * Checks if the program deliverable is 'Sujet Video'
     * @returns {boolean}
     */
    public isDeliverableVideo(): boolean {
        return this.event.programDeliverable?.slug === ProgramDeliverableSlug.SUJET_VIDEO
    }

    /**
     * Checks if the program deliverable is 'Sujet Web'
     * @returns {boolean}
     */
    public isDeliverableWeb(): boolean {
        return this.event.programDeliverable?.slug === ProgramDeliverableSlug.SUJET_WEB
    }

    /**
     * Checks if the video prefix should be added to the title
     */
    public isVideoContext(): boolean {
        return this.isProgramNoComment() || this.isDeliverableVideo()
    }

    public isLazyPublishWorkflow(): boolean {
        return this.isBreakingNews || this.isProgramNoComment()
    }

    /**
     * Checks if the event comes from a wire
     * @returns {boolean}
     */
    public isEventWire(): boolean {
        return this.event.eventSource?.id === WireImportDefaults.EVENT_SOURCE_ID
    }

    /**
     * Sets the titles & settings badge in the sidebar
     *
     * - Headline required
     * - Summary, Short title & Social title desired if not Breaking News
     *
     * @returns {IconMenuStatus}
     */
    public checkTitleAndSettings(): IconMenuStatus {
        if (!this.articleHeadline) {
            return IconMenuStatus.Error
        }

        if (!this.isBreakingNews && (!this.summary || !this.socialTitle)) {
            return IconMenuStatus.Warning
        }

        return IconMenuStatus.Valid
    }

    /**
     * Sets the main header badge in the sidebar
     *
     * - Image required if not Breaking News
     * - Metas should contain altText and copyright text
     *
     * @returns {IconMenuStatus}
     */
    public checkMainHeader(): IconMenuStatus {
        const mainheaderMetas = this.getMainImage()?.metasStory![0]
        const isEmptyMainHeaderMetas = !mainheaderMetas?.copyright?.text || !mainheaderMetas?.altText

        if (this.isTopNewsStoriesToday()) {
            if (isEmptyMainHeaderMetas) {
                return IconMenuStatus.Warning
            }

            return IconMenuStatus.Valid
        }

        if (!this.isBreakingNews && !this.getMainImage()) {
            return IconMenuStatus.Error
        }

        if (!this.isBreakingNews && !this.isMagazine() && isEmptyMainHeaderMetas) {
            return IconMenuStatus.Error
        }

        return IconMenuStatus.Valid
    }

    /**
     * Sets the tags & metadata badge in the sidebar
     *
     * - 1 tag required if Breaking News
     * - Tag & theme required if not Alert
     * - Tag & theme desired if Alert (although shouldn't a theme be compulsory?)
     *
     * @returns {IconMenuStatus}
     */
    public checkTagsAndMetadata(): IconMenuStatus {
        if (this.isLazyPublishWorkflow()) {
            return !this.tags || this.tags.length < 1 || this.tags.length > this.maxTagsAllowed
                ? IconMenuStatus.Error
                : IconMenuStatus.Valid
        }
        if (
            !this.tags ||
            this.tags.length < this.minTagsRequired ||
            this.tags.length > this.maxTagsAllowed ||
            this.themes.length < 1 ||
            (!this.isMagazine() && this.isMissingTagTranslation())
        ) {
            return this.isAlert() ? IconMenuStatus.Warning : IconMenuStatus.Error
        }

        return IconMenuStatus.Valid
    }

    /**
     * Check if there are enough translated tags
     *
     *
     * @returns {Boolean}
     */
    private isMissingTagTranslation = (): boolean => {
        let goodTranslatedTags = this.tags ? this.tags.length : 0

        for (const tag of this.tags) {
            if (
                !tag.translations.find(
                    translation => translation.language.id === this.language.id && translation.slug !== '',
                )
            ) {
                goodTranslatedTags--
            }
        }

        return goodTranslatedTags < this.minTagsRequired
    }

    /**
     * Sets the related articles badge in the sidebar
     *
     * - Related articles desired if not Alert or Breaking News
     *
     * @returns {IconMenuStatus}
     */
    public checkRelatedArticles(): IconMenuStatus {
        if (!this.related || this.related.length < 1) {
            if (this.isAlert() || this.isBreakingNews) {
                return IconMenuStatus.Neutral
            }

            return IconMenuStatus.Warning
        }

        return IconMenuStatus.Valid
    }

    /**
     * Sets the contributors badge in the sidebar
     *
     * - Contributors desired if not Breaking News
     * - Contributors valid of Author or Producer assigned
     *
     * @returns {IconMenuStatus}
     */
    public checkContributors(): IconMenuStatus {
        if ((!this.additionalReporting || !this.additionalReporting.contributors) && !this.author) {
            return this.isBreakingNews
                ? IconMenuStatus.Neutral
                : this.isMagazine()
                ? IconMenuStatus.Warning
                : IconMenuStatus.Error
        }

        return IconMenuStatus.Valid
    }

    public isCompleted(): boolean {
        return (
            this.checkTitleAndSettings() !== IconMenuStatus.Error &&
            this.checkMainHeader() !== IconMenuStatus.Error &&
            this.checkContributors() !== IconMenuStatus.Error &&
            this.checkTagsAndMetadata() !== IconMenuStatus.Error &&
            this.isNotOffline()
        )
    }

    /**
     * Sets the send to publication badge in the sidebar
     *
     * @returns {IconMenuStatus}
     */
    public checkSendToPublication(): IconMenuStatus {
        return this.isCompleted() ? IconMenuStatus.None : IconMenuStatus.Disabled
    }

    /**
     * Sets the offline badge in the sidebar
     * TODO: Remove after Pyramid migration
     * @returns {IconMenuStatus}
     */
    public checkOffline(): IconMenuStatus {
        return this.isIndexed ? IconMenuStatus.None : IconMenuStatus.Disabled
    }

    /**
     * We can only translate the main story (producer language)
     */
    public checkTranslate(): IconMenuStatus {
        let canTranslate = false

        // TODO : remove the first IF when removing Dalet Galaxy version
        if (this.event.daletVersionId === DaletVersionId.GALAXY) {
            canTranslate = !!this.event.producerLanguage && this.language.id === this.event.producerLanguage.id
        } else {
            canTranslate =
                !!this.event.producerLanguage &&
                this.language.id === this.event.producerLanguage.id &&
                [
                    PublicationStatus.APPROVED,
                    PublicationStatus.READY_TO_PUBLISH,
                    PublicationStatus.ONLINE,
                    PublicationStatus.OFFLINE,
                    PublicationStatus.SCHEDULED,
                ].includes(this.publicationStatus!.id)
        }

        return canTranslate ? IconMenuStatus.None : IconMenuStatus.Disabled
    }

    public isMagazine(): boolean {
        return Boolean(this.event && this.event.isMagazine)
    }

    /**
     * Return true if the dalet planning for the story event is in the planning 'PRODUCTION' (news)
     */
    public isPlanningNews(): boolean {
        return Boolean(this.daletPlanningArea && this.daletPlanningArea === 'PRODUCTION')
    }

    public isOnline(): boolean {
        return Boolean(
            this.publicationStatus &&
                [PublicationStatus.TO_REPUBLISH, PublicationStatus.ONLINE].includes(this.publicationStatus.id),
        )
    }

    public isNotOffline(): boolean {
        return Boolean(this.publicationStatus && this.publicationStatus.id !== PublicationStatus.OFFLINE)
    }

    public statusIsToRepublish(): boolean {
        return Boolean(this.publicationStatus && [PublicationStatus.TO_REPUBLISH].includes(this.publicationStatus.id))
    }

    public isTranslationStatusBlocked(): boolean {
        return PublicationStatus.IN_TRANSLATION === this.publicationStatus?.id
    }

    public isProgramTranslatedBlocked(): boolean {
        return !this.event.program?.translations.some(trans => trans.language.id === this.language.id)
    }

    // TODO remove ?
    public isReadyToPublish(): boolean {
        if (!this.isMagazine()) {
            return true
        }

        return Boolean(
            this.publicationStatus &&
                [
                    PublicationStatus.READY_TO_PUBLISH,
                    PublicationStatus.TO_REPUBLISH,
                    PublicationStatus.ONLINE,
                    PublicationStatus.SCHEDULED,
                ].includes(this.publicationStatus.id),
        )
    }

    public addText(storyText: StoryText): Story {
        let found = false

        if (!this.texts) {
            this.texts = []
        }

        this.texts.forEach(txt => {
            if (txt.format && txt.format.slug === storyText.format.slug) {
                txt.text = storyText.text
                found = true
            }
        })

        if (!found) {
            this.texts.push(storyText)
        }

        return this
    }

    public getTextByFormatType(formatType: StoryTextFormatsType): StoryText | null {
        let requestedFormat: StoryText | null = null

        if (this.texts.length) {
            requestedFormat = this.texts.find(txt => txt.format && txt.format.id === formatType) || null
        }

        return requestedFormat
    }

    public getMainImageMetas(): MetasImage {
        const image: Image | undefined = this.getMainImage()
        let baseMeta = {
            altText: '',
            caption: '',
            copyright: {
                text: '',
                link: '',
            },
            source: {
                text: '',
                link: '',
            },
        } as MetasImage

        baseMeta =
            (image && image.metasStory && image.metasStory.find((meta: MetasImage | undefined) => Boolean(meta))) ||
            baseMeta

        return baseMeta
    }

    public getMainImage(): Image | undefined {
        if (this.images && this.images.length > 0) {
            return this.images.find(el => {
                return el.storyImageTypeId === StoryImageType.mainHeader
            })
        }

        return
    }

    public getTagsInWishedLanguage(langId: number): string[] {
        const translatedTags: string[] = []

        this.tags?.forEach((tag: TagModel) => {
            const translationsInLanguage = tag.translations.filter(
                (translation: TranslationTagModel) => translation.language.id === langId,
            )

            if (translationsInLanguage.length > 0) {
                const translatedTitle = translationsInLanguage[0].title
                translatedTags.push(translatedTitle)
            }
        })

        return translatedTags
    }

    /**
     * @returns {{id: number, slug: string, canonicalUrl: string}}
     */
    getPublicationOwner(): PublicationOwnerModel {
        return this.event.publicationOwner
            ? this.event.publicationOwner
            : {
                  id: -1,
                  slug: PublicationOwnerName.Euronews,
                  canonicalUrl: '',
              }
    }

    public getProducerLanguageId(): number | undefined {
        return this.event.producerLanguage?.id
    }

    public getProducerLanguageStory(): StoryModel | undefined {
        const producerLanguageId = this.getProducerLanguageId()

        return this.event.stories?.find(story => story.language.id === producerLanguageId && story.id !== this.id)
    }

    public canBeEdited(): boolean {
        return typeof this.type.slug !== 'undefined' && this.type.slug !== StoryTypeSlug.EURONEWS_NBC
    }

    public canBeEditedByUser(auth: AuthService): boolean {
        const userRoles = auth.currentUser?.getRoles() || []

        // If user is a journalist and the story is not in his default language, we lock the edition)
        if (
            userRoles.every(role => role === UserRole.JOURNALIST) &&
            !auth.currentUser?.getLanguageIsoCodes().includes(this.language.isoCode)
        ) {
            return false
        }

        return this.canBeEdited()
    }

    public hasTextileText(): boolean {
        return this.hasTextForFormat(StoryTextFormatsSlug.TEXTILE)
    }

    public hasMarkdownText(): boolean {
        return this.hasTextForFormat(StoryTextFormatsSlug.MARKDOWN)
    }

    public hasPlainText(): boolean {
        return this.hasTextForFormat(StoryTextFormatsSlug.PLAINTEXT)
    }

    public getPlainText(): StoryText[] {
        return this.texts.filter(
            text => text.format.slug === StoryTextFormatsSlug.PLAINTEXT && text.text.replace(/\s/g, '') !== '',
        )
    }

    public hasTextForFormat(format: StoryTextFormatsSlug): boolean {
        return (
            this.texts.filter(text => text.format.slug === format && text.text.replace(/\s/g, '') !== '').length === 1
        )
    }

    public hasCountry(): boolean {
        return !!this.geo?.country
    }

    public isUnderEmbargo() {
        return !!(this.event.embargoDate && dayjs().isBefore(dayjs(this.event.embargoDate)))
    }

    getVideosHousenumber(): string | undefined {
        let housenumber

        if (this.videos) {
            const video = this.videos.find(vdo => Boolean(vdo.housenumber))

            housenumber = video && video.housenumber
        }

        return housenumber
    }

    /**
     * static
     *
     * @param {string} url
     * @param {PublicationOwnerModel} publicationOwner
     * @returns {string}
     */
    setUrlToCorrectServer(url: string | undefined, publicationOwner: PublicationOwnerModel): string {
        if (environment.production) {
            return url as string
        }

        if (!url) {
            throw new Error(
                'setUrlToCorrectServer requires a url or a story.event.publicationOwner.canonicalUrl property',
            )
        }

        if (!publicationOwner || !publicationOwner.slug) {
            throw new Error('setUrlToCorrectServer requires a publicationOwner')
        }

        const spliter = url.match(new RegExp(`${environment[publicationOwner.slug].host}`))
            ? environment[publicationOwner.slug].host
            : environment[publicationOwner.slug].prodHost

        const urlParts = url.split(spliter)
        if (urlParts.length > 1) {
            url =
                urlParts.shift() +
                environment[publicationOwner.slug].host +
                urlParts.join(environment[publicationOwner.slug].prodHost)
        }

        return url
    }

    /**
     * construct the web url of a story
     *
     * @returns {string | null}
     */
    getStoryOnlineUrl(): string | null {
        if (
            !this.publishedAt ||
            !this.urlTitleSlug ||
            !this.language.websiteSubdomain ||
            !this.event ||
            !this.event.program ||
            !this.event.publicationOwner
        ) {
            return null
        }

        let storyUrl = this.setUrlToCorrectServer(
            this.event.program.canonicalUrl,
            this.event.publicationOwner,
        ) as string
        const date = new Date(this.publishedAt)

        const replaceStr = ['langcode_url', 'Y', 'm', 'd', 'urlOnlyTitle', 'verticalUrlSafeValue']
        const month = this.padStr(String(date.getMonth() + 1), 2)
        const day = this.padStr(String(date.getDate()), 2)
        const verticalUrlSafeValue = this.getVerticalUrlSafeValue()
        const replaceWith = [
            this.language.websiteSubdomain,
            String(date.getFullYear()),
            month,
            day,
            this.urlTitleSlug,
            verticalUrlSafeValue,
        ]

        if (this.event.programDeliverable?.slug === ProgramDeliverableSlug.SUJET_VIDEO) {
            storyUrl = storyUrl.replace('/{{verticalUrlSafeValue}}', '')
            storyUrl = storyUrl.replace('/{{Y}}', '/video/{{Y}}')
        }

        for (let index = 0; index < replaceStr.length; index++) {
            storyUrl = storyUrl.replace(new RegExp('{{' + replaceStr[index] + '}}', 'gi'), replaceWith[index])
        }

        return storyUrl
    }

    getVerticalUrlSafeValue(): string {
        const verticalList = this.event.program?.theme?.verticals
        if (!verticalList || verticalList.length < 1) {
            return ''
        }

        const vertical = verticalList[0].translations.find(el => el.language?.isoCode === this.language.isoCode)

        return vertical && vertical.slug ? vertical.slug : ''
    }

    /**
     * Get the selected campaign if there is one set up or 0
     */
    public getSelectedCampaingId(): number {
        return this.campaigns && this.campaigns.length ? this.campaigns[0].id : 0
    }

    private padStr(str: string, padLength: number, padStr: string = '0') {
        return Array(padLength - String(str).length + 1).join(padStr) + str
    }

    /**
     *
     * @param story Story to examin as being 'Sujet video' and having no video attached.
     * @returns {boolean}
     */
    public isAwaitingVideoAttachement(): boolean {
        return this.publicationStatus?.id === PublicationStatus.AWAITING
    }

    /**
     * Check if the story is in status awaiting and has a publicationDate
     *
     * @returns {boolean}
     */
    public isAwaitingToBeScheduled(): boolean {
        return Boolean(
            this.publicationStatus?.id === PublicationStatus.AWAITING &&
                this.publishedAt &&
                dayjs(this.publishedAt).isAfter(dayjs()),
        )
    }

    public hasOpinionDisclaimer(): boolean {
        return this.tags.some(tag => tag.showOpinionDisclaimer)
    }
}
