import { gql } from '@apollo/client/core'
import { tagFragment } from 'services/tags/tags.fragment'

export const settingsQuery = gql`
    query aiSettings {
        aiSettings {
            id
            slug
            title
            systemInstructionsPrompt
            taskPrompt
            instructionsPrompt
            charLimitMax
            charLimitRecoMin
            charLimitRecoMax
            temperature
        }
    }
`

export const updateSettingsMutation = gql`
    mutation updateAISettings($aiSettings: AISettingsInput!) {
        updateAISettings(aiSettings: $aiSettings)
    }
`

export const generateMainHeadlineQuery = gql`
    query aiGenerateMainHeadline($storyId: Int!) {
        aiGenerateMainHeadline(storyId: $storyId)
    }
`

export const generateRunningTitleQuery = gql`
    query aiGenerateRunningTitle($mainHeadline: String!) {
        aiGenerateRunningTitle(mainHeadline: $mainHeadline)
    }
`

export const generateStoryTwitterTextQuery = gql`
    query aiGenerateStoryTwitterText($storyId: Int!) {
        aiGenerateStoryTwitterText(storyId: $storyId)
    }
`

export const generateSummaryQuery = gql`
    query aiGenerateSummary($storyId: Int!) {
        aiGenerateSummary(storyId: $storyId)
    }
`

export const generateEditorialTagsDescriptionsMutation = gql`
    ${tagFragment}

    mutation aiGenerateEditorialTagsDescriptions($slugs: [String]!, $overwrite: Boolean!) {
        aiGenerateEditorialTagsDescriptions(slugs: $slugs, overwrite: $overwrite) {
            ...tagInfo
        }
    }
`

export const translateEditorialTagsDescriptionsMutation = gql`
    ${tagFragment}

    mutation aiTranslateEditorialTagsDescriptions($tagIds: [Int]!, $overwrite: Boolean!) {
        aiTranslateEditorialTagsDescriptions(tagIds: $tagIds, overwrite: $overwrite) {
            ...tagInfo
        }
    }
`
