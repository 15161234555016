import { Injectable } from '@angular/core'
import { EventPyramidStatus, EventPyramidStatusAbbreviation } from 'enums/event-pyramid-status'
import { PyramidDesk, PyramidDeskLabel } from 'enums/pyramid-desk'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { AuthService } from 'services/auth/auth'
import { LanguagesService } from 'services/languages/languages'
import { FilterableInterface } from '../../../app/shared/filter/datagrid-filterables/filterables.interface'
import { User } from '../../../entities/user'
import { PublicationOwnerEnum } from '../../../enums/publication-owner'
import { PublicationStatusLabelsById } from '../../../enums/publication-status'
import { StoryType, StoryTypeTranslation } from '../../../models/story.model'
import { ProgramDeliverableListService } from '../../../services/program-deliverables/list'
import { ProgramListService } from '../../../services/programs/list'
import { StoryTypesListService } from '../../../services/story/types'
import { DashboardFilterHelper } from '../../helpers/dashboard-filters.helper'
import { DashboardConstantsService } from '../dashboard-constants.service'

@Injectable()
export class DashboardFiltersListService {
    constructor(
        private storyTypesListService: StoryTypesListService,
        private programDeliverablesListService: ProgramDeliverableListService,
        private programListService: ProgramListService,
        private authService: AuthService,
        private languagesService: LanguagesService,
        private dashboardConstantsService: DashboardConstantsService,
    ) {}

    public loadStoryTypeList(languageId: number): Observable<FilterableInterface[]> {
        return this.storyTypesListService.getAllStoryTypes().pipe(
            map((storyTypes: StoryType[]) =>
                storyTypes.map((storyType: StoryType) => {
                    const contributorTranslation: StoryTypeTranslation | null =
                        DashboardFilterHelper.getLanguageObject(languageId, storyType) ||
                        DashboardFilterHelper.getLanguageObject(this.languagesService.defaultLanguage.id, storyType)

                    return {
                        id: storyType.id,
                        label: contributorTranslation!.title,
                        group: '',
                    }
                }),
            ),
        )
    }

    public loadlanguageFilterList(): FilterableInterface[] {
        return this.languagesService.languages
            .map(language => ({
                id: language.id,
                label: language.fullname!,
                group: '',
                selected: this.authService.getUserDefaultLanguageId() === language.id,
            }))
            .sort(DashboardFilterHelper.compareFilterableByLabel)
    }

    public loadChannelFilterList(): FilterableInterface[] {
        return this.dashboardConstantsService.PUBLICATION_OWNERS.map(channelId => ({
            id: channelId,
            label: PublicationOwnerEnum[channelId],
            group: '',
        }))
    }

    public loadDeskFilterList(): FilterableInterface[] {
        return this.dashboardConstantsService.DESKS_TO_DISPLAY.map(id => ({
            id,
            label: PyramidDeskLabel[PyramidDesk[id]],
            group: '',
        }))
    }

    public loadEventPyramidStatusFilterList(): FilterableInterface[] {
        return this.dashboardConstantsService.EVENT_PYRAMID_STATUSES_TO_DISPLAY.map(id => ({
            id,
            label: EventPyramidStatusAbbreviation[EventPyramidStatus[id]],
            group: 'statuses-filters',
        }))
    }

    public loadStatusFilterList(): FilterableInterface[] {
        return this.dashboardConstantsService.STATUS_TO_DISPLAY.map(id => ({
            id,
            label: PublicationStatusLabelsById[id],
            group: 'statuses-filters',
        }))
    }

    public loadProgramsList(contributor: User): Observable<FilterableInterface[]> {
        return this.programListService.programs.pipe(
            map(programs =>
                DashboardFilterHelper.formatProgramAsFilterable(programs, contributor).sort(
                    DashboardFilterHelper.compareFilterableByLabel,
                ),
            ),
        )
    }

    public runProgramListLoader(): void {
        this.programListService.getAllForSearch()
    }

    public loadCurrentContributorList(contributor: User): FilterableInterface[] {
        return [
            {
                id: contributor.id,
                label: 'My stories',
                group: '',
            },
        ]
    }

    public loadProgramDeliverablesList(): Observable<FilterableInterface[]> {
        return this.programDeliverablesListService.programDeliverables.pipe(
            take(1),
            map(results =>
                results.map(deliverable => ({
                    id: deliverable.id,
                    label: deliverable.title,
                    group: '',
                })),
            ),
        )
    }

    public runProgramDeliverableListLoader(limit: number, offset: number): void {
        this.programDeliverablesListService.get({ isActive: true }, limit, offset)
    }
}
